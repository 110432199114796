import { Container, Typography, TextField, Grid, Paper, Button } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, useParams } from 'react-router-dom';

export default function Referido() {
    const { token } = useToken();
    const { id } = useParams();
    const [referido, setReferido] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if(token){
            if(id !== "new"){
                fetch("https://luisa.zopcloud.net:8081/referido/" + id, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setReferido(json);
                    setLoading(false);
                }));
            }else{
                setReferido({});
                setLoading(false);
            }
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/" replace={true} />
        )
    }

    if(loading){
        return(
            <></>
        )
    }

    const guardar = () =>{
        if(window.confirm("¿Estás segura que quieres guardar los cambios?")){
            fetch("https://luisa.zopcloud.net:8081/referido", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(referido)
            }).then(r => r.json().then(json => {
                setReferido(json);
                console.log("Guardado")
                if(id === "new"){
                    window.location.replace("/referido/" + json.id)
                }else{
                    alert("Cambios guardados.");
                }
            }))
        }
    }

    return(
        <Container sx={{marginTop: 1}}>
            <Typography variant='h5'>Referenciador - {id === "new" ? "Nuevo" : referido.name}</Typography>
            <Paper fullWidth sx={{marginY: 1}}>
                <Grid container>
                    <Grid item md={12} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Nombre" variant="outlined" defaultValue={referido.name} onChange={(e) => setReferido({...referido, name: e.target.value})} />
                    </Grid>
                </Grid>
            </Paper>
            <Button onClick={guardar}>Guardar</Button>

        </Container>
    )
}