import { Container, Typography, TextField, Grid, Paper, Button, MenuItem } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, useParams } from 'react-router-dom';

export default function Impuesto() {
    const { token } = useToken();
    const { id } = useParams();
    const [impuesto, setImpuesto] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if(token){
            if(id !== "new"){
                fetch("https://luisa.zopcloud.net:8081/impuesto/" + id, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setImpuesto(json);
                    setLoading(false);
                }));
            }else{
                setImpuesto({});
                setLoading(false);
            }
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/" replace={true} />
        )
    }

    if(loading){
        return(
            <></>
        )
    }

    const guardar = () =>{
        fetch("https://luisa.zopcloud.net:8081/impuesto", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(impuesto)
        }).then(r => r.json().then(json => {
            setImpuesto(json);
            console.log("Guardado")
            if(id === "new"){
                window.location.replace("/impuestos")
            }else{
                alert("Cambios guardados.");
            }
        }))
    }

    return(
        <Container sx={{marginTop: 1}}>
            <Typography variant='h5'>Impuesto - {id === "new" ? "Nuevo" : impuesto.id}</Typography>
            <Paper fullWidth sx={{marginY: 1}}>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Impuesto" variant="outlined" defaultValue={impuesto.id} onChange={(e) => setImpuesto({...impuesto, id: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth select id="outlined-basic" label="Tipo" variant="outlined" defaultValue={impuesto.tipo} onChange={(e) => setImpuesto({...impuesto, tipo: e.target.value})}>
                            <MenuItem value={"TRIMESTRAL"}>
                                Trimestral
                            </MenuItem>
                            <MenuItem value={"ANUAL"}>
                                Anual
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Meses" variant="outlined" defaultValue={impuesto.months} onChange={(e) => setImpuesto({...impuesto, months: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Día" variant="outlined" defaultValue={impuesto.day} onChange={(e) => setImpuesto({...impuesto, day: e.target.value})} />
                    </Grid>
                </Grid>
            </Paper>
            <Button onClick={guardar}>Guardar</Button>

        </Container>
    )
}