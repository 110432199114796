import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Clientes from './pages/Clientes';
import Cliente from './pages/Cliente';
import Header from './components/Header';
import Login from './pages/Login';
import Caso from './pages/Caso';
import Casos from './pages/Casos';
import Referidos from './pages/Referidos';
import Referido from './pages/Referido';
import Impuestos from './pages/Impuestos';
import Impuesto from './pages/Impuesto';
import CasosTodos from './pages/CasosTodos';
import Listado from './pages/Listado';

function App() {
  return (
    <>
    <Header />
    <Routes>
      <Route path='/' element={<Inicio />} />
      <Route path='/login' element={<Login />} />
      <Route path='/clientes' element={<Clientes />} />
      <Route path='/cliente/:id' element={<Cliente />} />
      <Route path='/casos' element={<Casos />} />
      <Route path='/casos/todos' element={<CasosTodos />} />
      <Route path='/caso/:id' element={<Caso />} />
      <Route path='/referidos' element={<Referidos />} />
      <Route path='/referido/:id' element={<Referido />} />
      <Route path='/impuestos' element={<Impuestos />} />
      <Route path='/impuesto/:id' element={<Impuesto />} />
      <Route path='/lista' element={<Listado />} />
    </Routes>
    </>
  );
}

export default App;
