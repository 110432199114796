import { Container, Typography, TextField, Button } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate } from 'react-router-dom';

export default function Login() {
    const { token, setToken } = useToken();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    React.useEffect(() => {

    }, []);

    if(token){
        return(
            <Navigate to="/" replace={true} />
        )
    }

    const login = () => {
        const body = {username: username, password: password};
        fetch('https://luisa.zopcloud.net:8081/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        }).then(res => res.text().then(body => {
            if(body === "wrong"){
                console.log("Wrong username and/or password");
            }else{
                setToken(body);
                window.location.reload();
            }
        }));
    }

    return(
        <Container>
            <Typography variant='h3'>Iniciar sesión</Typography>
            <TextField fullWidth sx={{marginY: 1}} id="username" label="Nombre de usuario" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
            <TextField fullWidth sx={{marginY: 1}} id="password" type="password" label="Contraseña" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Button onClick={login}>Acceder</Button>
        </Container>
    )
}