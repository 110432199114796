import { Container, Typography, TextField, Grid, Paper, Button, MenuItem, List, ListItem, ListItemText, Divider, ListItemButton, IconButton } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, useParams } from 'react-router-dom';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function Caso() {
    const { token } = useToken();
    const { id } = useParams();
    const [caso, setCaso] = React.useState({cliente: {nombre : ""}});
    const [loading, setLoading] = React.useState(true);

    const [fechaInicio, setInicio] = React.useState(dayjs(null));
    const [fechaFin, setFin] = React.useState(dayjs(null));
    const [fechaReq, setReq] = React.useState(dayjs(null));

    const [clientes, setClientes] = React.useState([]);

    React.useEffect(() => {
        if(token){
            if(id != "new"){
                fetch("https://luisa.zopcloud.net:8081/caso/" + id, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setCaso(json);
                    console.log(json);
                    setInicio(json.fechaInicio);
                    setFin(json.fechaFin);
                    setReq(json.fechaReq);
                    setLoading(false);
                }));
            }else{
                fetch("https://luisa.zopcloud.net:8081/clientes", {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setClientes(json);
                    setLoading(false);
                }));
            }
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/" replace={true} />
        )
    }

    if(loading){
        return(
            <></>
        )
    }

    const guardar = () =>{
        if(window.confirm("¿Estás segura que quieres guardar los cambios?")){
            fetch("https://luisa.zopcloud.net:8081/caso", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(caso)
            }).then(r => r.json().then(json => {
                setCaso(json);
                console.log("Guardado")
                if(id === "new"){
                    window.location.replace("/caso/" + json.id)
                }else{
                    alert("Cambios guardados.");
                }
            }))
        }
    }

    const handleInicioDate = (v) => {
        setInicio(v);
        if(!v){
            setCaso({...caso, fechaInicio: null});
        }else{
            setCaso({...caso, fechaInicio: v.format('YYYY-MM-DD')});
        }
    }

    const handleFinDate = (v) => {
        setFin(v);
        if(!v){
            setCaso({...caso, fechaFin: null});
        }else{
            setCaso({...caso, fechaFin: v.format('YYYY-MM-DD')});
        }
    }

    const handleReqDate = (v) => {
        setReq(v);
        if(!v){
            setCaso({...caso, fechaReq: null});
        }else{
            setCaso({...caso, fechaReq: v.format('YYYY-MM-DD')});
        }
    } 

    return(
        <Container sx={{marginTop: 1}}>
            <Typography variant='h5'>CASO {id !== "new" ? caso.identificador : ""} - {id !== "new" ? caso.descripcion : "Nuevo"}</Typography>
            <Paper fullWidth sx={{marginY: 1}}>
                <Grid container>
                    <Grid item md={2} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Id." variant="outlined" defaultValue={caso.identificador} onChange={(e) => setCaso({...caso, identificador: e.target.value})} />
                    </Grid>
                    <Grid item md={10} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Descripción" variant="outlined" defaultValue={caso.descripcion} onChange={(e) => setCaso({...caso, descripcion: e.target.value})} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={id != "new" ? 6 : 12} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth InputProps={{readOnly: id !== "new"}} select={id === "new"} id="outlined-basic" label="Cliente" variant="outlined" defaultValue={caso.cliente.nombre} onChange={(e) => setCaso({...caso, cliente: {id: e.target.value}})}>
                        {id === "new" ? 
                            clientes.map(m => (
                                <MenuItem key={m.id} value={m.id}>
                                    {m.nombre}
                                </MenuItem>
                            ))
                        : <></>}
                        </TextField>
                    </Grid>
                    {id !== "new" ?
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth InputProps={{readOnly: true}} id="outlined-basic" label="Referido" variant="outlined" defaultValue={caso.cliente.referido ? caso.cliente.referido.name : "No referido"}/>
                    </Grid>
                    : <></>}
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                    <Grid item md={id !== "new" ? 6 : 12} xs={12} sx={{padding: 1}}>
                        <DesktopDatePicker label="Fecha inicio" inputFormat='DD/MM/YYYY' value={fechaInicio} onChange={handleInicioDate} renderInput={(params) => <TextField fullWidth {...params} />} />
                    </Grid>
                    {id !== "new" ?
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <DesktopDatePicker label="Fecha finalización" inputFormat='DD/MM/YYYY' value={fechaFin} onChange={handleFinDate} renderInput={(params) => <TextField fullWidth {...params} />} />
                    </Grid>
                    : <></> }
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <DesktopDatePicker label="Fecha límite" inputFormat='DD/MM/YYYY' value={fechaReq} onChange={handleReqDate} renderInput={(params) => <TextField fullWidth {...params} />} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Precio" variant="outlined" defaultValue={caso.precio} onChange={(e) => setCaso({...caso, precio: e.target.value})} />
                    </Grid>
                </Grid>
                </LocalizationProvider>
            </Paper>
            <Paper fullWidth sx={{padding: 1}}>
                <TextField fullWidth id="outlined-basic" label="Notas" variant="outlined" multiline rows={4} defaultValue={caso.notas} onChange={(e) => setCaso({...caso, notas: e.target.value})} />
            </Paper>
            <Button onClick={guardar}>Guardar</Button>
        </Container>
    )
}