import { Container, Grid, Typography, List, ListItemButton, ListItemText, Divider, ListItem, Paper } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, Link } from 'react-router-dom';

export default function Inicio() {
    const { token } = useToken();

    const [r, setR] = React.useState([]);
    const [imp, setImp] = React.useState([]);
    const [req, setReq] = React.useState([]);

    React.useEffect(() => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/casos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setR(json);
            }))
            fetch("https://luisa.zopcloud.net:8081/impuestos/0", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setImp(json);
            }))
            fetch("https://luisa.zopcloud.net:8081/casos/requerimientos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setReq(json);
            }))
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/login" replace={true} />
        )
    }

    return(
        <Container>
            <Typography variant='h3'>Hola</Typography>
            <Grid container>
                <Grid item md={6} xs={12} sx={{paddingRight: 1, paddingTop: 1}}>
                    <Paper fullWidth>
                        <List>
                            <ListItem>
                                <ListItemText><Typography fontWeight={'bold'}>Últimos casos abiertos</Typography></ListItemText>
                            </ListItem>
                            {r.slice(0,5).map((m, i) => (
                                <>
                                <Divider component="li" />
                                <ListItemButton key={i} component={Link} to={"/caso/" + m.id}>
                                    <ListItemText>{m.descripcion} - {m.cliente.nombre}</ListItemText>
                                </ListItemButton>
                                </>
                            ))}
                        </List>
                    </Paper>
                    <Paper fullWidth sx={{marginTop: 2}}>
                        <List>
                            <ListItem>
                                <ListItemText><Typography fontWeight={'bold'}>Fechas límite</Typography></ListItemText>
                            </ListItem>
                            {req.map((m, i) => (
                                <>
                                <Divider component="li" />
                                <ListItemButton key={i} component={Link} to={"/caso/" + m.id}>
                                    <ListItemText>{new Date(m.fechaReq).toLocaleDateString('es-ES')} - {m.descripcion}</ListItemText>
                                </ListItemButton>
                                </>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12} sx={{paddingLeft: 1, paddingTop: 1}}>
                    <Paper fullWidth>
                        <List>
                            <ListItem>
                                <ListItemText><Typography fontWeight={'bold'}>Próximos eventos</Typography></ListItemText>
                            </ListItem>
                            {imp.map((m, i) => (
                                <>
                                <Divider component="li" />
                                <ListItemButton key={i}>
                                    <ListItemText>Del 1 al {m.day} - Modelo {m.id}</ListItemText>
                                </ListItemButton>
                                </>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}