import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, useParams } from 'react-router-dom';
import { Container, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Button, Typography } from '@mui/material';

export default function Listado() {
    const { token } = useToken();
    const [impuestos, setImpuestos] = React.useState([]);
    const [respuesta, setRespuesta] = React.useState("");

    const [selectedImp, setSelectedImp] = React.useState([]);

    const solicitar = () => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/excel/" + selectedImp.join(','), {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token,
                    'Content-Type': 'application/pdf'
                }
            }).then(r => r.blob()).then(b => {
                const url = window.URL.createObjectURL(
                    new Blob([b]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'Listado.xlsx',
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        }
    }

    const solicitarEmails = () => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/emails/" + selectedImp.join(','), {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token,
                    'Content-Type': 'application/pdf'
                }
            }).then(r => r.text()).then(t => setRespuesta(t));
        }
    }

    const handleImpChange = (e) => {
        const {
            target : { value },
        } = e;
        setSelectedImp(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    React.useEffect(() => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/impuestos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setImpuestos(json);
            }));
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/login" replace={true} />
        )
    }

    return(
        <Container style={{paddingTop: 10}}>
        <Typography variant='h5'>Descargar Listados</Typography>
        <Select
            multiple
            value={selectedImp}
            onChange={handleImpChange}
            fullWidth
            input={<OutlinedInput label="Modelos" />}
            renderValue={(selected) => selected.join(', ')}
        >
            {impuestos.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                    <Checkbox checked={selectedImp.indexOf(i.id) > -1} />
                    <ListItemText primary={i.id} />
                </MenuItem>
            ))}
        </Select>
        <br />
        <br/>
        <Button variant="contained" onClick={solicitar}>Descargar Excel</Button>
        <br/>
        <br/>
        <Button variant="contained" onClick={solicitarEmails}>Lista de emails</Button>
        <Typography>{respuesta}</Typography>
        </Container>
    )
}