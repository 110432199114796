import { Container, Typography, Button, Box } from '@mui/material';
import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import useToken from '../utils/Token';
import { Navigate, Link } from 'react-router-dom';

const columns = [
    { field: 'identificador', headerName: 'ID', flex: 1 },
    { field: 'cliente', headerName: 'Cliente', flex: 1, valueGetter: (p) => p.value ? p.value.nombre : ""},
    { field: 'descripcion', headerName: 'Descripción', flex: 1 },
];

export default function Casos() {
    const { token, setToken } = useToken();
    const [r, setR] = React.useState([]);

    const solicitarListado = () => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/inf/casos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token,
                    'Content-Type': 'application/pdf'
                }
            }).then(r => r.blob()).then(b => {
                const url = window.URL.createObjectURL(
                    new Blob([b]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'Listado.pdf',
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        }
    }

    const solicitarExcel = () => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/excel/casos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token,
                    'Content-Type': 'application/pdf'
                }
            }).then(r => r.blob()).then(b => {
                const url = window.URL.createObjectURL(
                    new Blob([b]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'Casos.xlsx',
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        }
    }

    React.useEffect(() => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/casos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => {
                if(r.status != 200){
                    setToken("");
                    window.location.reload();
                }else{
                    r.json().then(json => {
                        setR(json);
                    })
                }
            })
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/login" replace={true} />
        )
    }

    return(
        <Container>
            <Typography variant='h2'>Casos abiertos</Typography>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={r}
              columns={columns}
              pageSize={5}
              components={{ Toolbar: QuickSearchToolbar }}
              onCellDoubleClick={(p) => window.location.replace("/caso/" + p.id)}
            />
            </div>
            <br/>
            <br/>
            <Button variant="contained" component={Link} to="/casos/todos">Ver todos los casos</Button>
            <br/>
            <br/>
            <Button variant="contained" style={{marginRight: 10}} onClick={solicitarExcel}>Descargar Excel</Button>
            <Button variant="contained" onClick={solicitarListado}>Descargar PDF</Button>
        </Container>
    )
}

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
}