import { Container, Typography, TextField, Grid, Paper, Button, List, ListItem, ListItemText, Divider, ListItemButton, MenuItem, Modal, Box, Select, OutlinedInput, Checkbox } from '@mui/material';
import * as React from 'react';
import useToken from '../utils/Token';
import { Navigate, useParams, Link } from 'react-router-dom';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function Cliente() {
    const { token } = useToken();
    const { id } = useParams();
    const [cliente, setCliente] = React.useState({});
    const [casos, setCasos] = React.useState([]);
    const [impuestos, setImpuestos] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [referidos, setReferidos] = React.useState([]);

    const [fechaInicio, setInicio] = React.useState(dayjs(null));
    const [fechaFin, setFin] = React.useState(dayjs(null));

    const [openModal, setOpenModal] = React.useState(false);
    const [selectedImp, setSelectedImp] = React.useState([]);

    const handleImpChange = (e) => {
        const {
            target : { value },
        } = e;
        const imps = typeof value === 'string' ? value.split(',') : value;
        const impse = [];
        setSelectedImp(
            typeof value === 'string' ? value.split(',') : value,
        );
        imps.forEach(i => {
            impse.push({id: i});
        })
        setCliente({...cliente, impuestos: impse});
    }

    const handleInicioDate = (v) => {
        setInicio(v);
        if(!v){
            setCliente({...cliente, altaActividad: null});
        }else {
            setCliente({...cliente, altaActividad: v.format('YYYY-MM-DD')});
        }
        
    }

    const handleFinDate = (v) => {
        setFin(v);
        if(!v){
            setCliente({...cliente, bajaActividad: null});
        }else {
            setCliente({...cliente, bajaActividad: v.format('YYYY-MM-DD')});
        }
    }

    const checkNIF = (nif) => {
        if(nif.length == 9){
            if(nif[0] != 'X' && nif[0] != 'Y' && nif[0] != '0' && nif[0] != '1' && nif[0] != '2' && nif[0] != '3' && nif[0] != '4' && nif[0] != '5' && nif[0] != '6' && nif[0] != '7' && nif[0] != '8' && nif[0] != '9' && nif[0] != '0'){
              console.log(nif.slice(1,8))
              var pares = Number.parseInt(nif[2]) + Number.parseInt(nif[4]) + Number.parseInt(nif[6]);
              var impar1 = Number.parseInt((Number.parseInt(nif[1])*2).toString()[0]) + Number.parseInt((Number.parseInt(nif[1])*2).toString()[1]);
              var impar2 = Number.parseInt((Number.parseInt(nif[3])*2).toString()[0]) + Number.parseInt((Number.parseInt(nif[3])*2).toString()[1]);
              var impar3 = Number.parseInt((Number.parseInt(nif[5])*2).toString()[0]) + Number.parseInt((Number.parseInt(nif[5])*2).toString()[1]);
              var impar4 = Number.parseInt((Number.parseInt(nif[7])*2).toString()[0]) + Number.parseInt((Number.parseInt(nif[7])*2).toString()[1]);
              var total = pares + impar1 + impar2 + impar3 + impar4;
              if(total%10 == nif[8]){
                console.log(true);
              }else{
                console.log(false);
              }
            }else{
              const letras = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
              if(nif[0] == 'X'){
                nif[0] = '0';
              }else if(nif[0] == 'Y'){
                nif[0] = '1';
              }else if(nif[0] == 'Z'){
                nif[0] = '2';
              }
                var string = nif.slice(0, 8);
                var number = Number.parseInt(string);
                if(letras[number%23] == nif[8]){
                    console.log(true);
                }else{
                    console.log(false);
                }
            }
          }
    }

    React.useEffect(() => {
        if(token){
            if(id !== "new"){
                fetch("https://luisa.zopcloud.net:8081/cliente/" + id, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setCliente(json);
                    setInicio(json.altaActividad);
                    setFin(json.bajaActividad);
                    json.impuestos.forEach(element => {
                        if(!selectedImp.includes(element.id)){
                            selectedImp.push(element.id);
                        }      
                    });
                    fetch("https://luisa.zopcloud.net:8081/casos/" + id, {
                        method: "GET",
                        headers: {
                            "Authorization": "Bearer " + token
                        }
                    }).then(r => r.json().then(json => {
                        setCasos(json);
                        fetch("https://luisa.zopcloud.net:8081/impuestos", {
                            method: "GET",
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }).then(r => r.json().then(json => {
                            setImpuestos(json);
                            setLoading(false);
                        }));
                    }));
                }));
            }else{
                setCliente({impuestos: []});
                fetch("https://luisa.zopcloud.net:8081/referidos", {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(r => r.json().then(json => {
                    setReferidos(json);
                    fetch("https://luisa.zopcloud.net:8081/impuestos", {
                            method: "GET",
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }).then(r => r.json().then(json => {
                            setImpuestos(json);
                            setLoading(false);
                        }));
                }));
            }
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/" replace={true} />
        )
    }

    if(loading){
        return(
            <></>
        )
    }

    const guardar = () =>{
        if(window.confirm("¿Estás segura que quieres guardar los cambios?")){
            fetch("https://luisa.zopcloud.net:8081/cliente", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(cliente)
            }).then(r => r.json().then(json => {
                setCliente(json);
                console.log("Guardado")
                if(id === "new"){
                    window.location.replace("/cliente/" + json.id)
                }else{
                    alert("Cambios guardados.");
                }
            }))
        }
    }

    const eliminar = () =>{
        if(window.confirm("¿Estás segura que quieres eliminar el cliente? Recuerda que no puede tener ni casos ni impuestos asociados.")){
            fetch("https://luisa.zopcloud.net:8081/delete/cliente", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({id: cliente.id})
            }).then(r => {
                if(r.ok){
                    r.json().then(json => {
                        window.location.replace("/clientes");
                    })
                }else{
                    alert("Error. Comprueba que no haya casos ni impuestos asociados.");
                }
            })}
    }

    return(
        <Container sx={{marginTop: 1}}>
            <Typography variant='h5'>CLIENTE - {id === "new" ? "Nuevo" : cliente.nombre}</Typography>
            <Paper fullWidth sx={{marginY: 1}}>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Nombre" variant="outlined" defaultValue={cliente.nombre} onChange={(e) => setCliente({...cliente, nombre: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="NIF" variant="outlined" defaultValue={cliente.nif} onChange={(e) => setCliente({...cliente, nif: e.target.value})}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth select id="outlined-basic" label="Estado civil" variant="outlined" defaultValue={cliente.estado_civil} onChange={(e) => setCliente({...cliente, estado_civil: e.target.value})}>
                            <MenuItem value={"Soltero"}>
                                Soltero
                            </MenuItem>
                            <MenuItem value={"Casado"}>
                                Casado
                            </MenuItem>
                            <MenuItem value={"Divorciado"}>
                                Divorciado
                            </MenuItem>
                            <MenuItem value={"Viudo"}>
                                Viudo
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth InputProps={{readOnly: id !== "new"}} id="outlined-basic" select={id === "new"} label="Referencia" variant="outlined" defaultValue={cliente.referido ? cliente.referido.name : "No referido"} onChange={(e) => setCliente({...cliente, referido: {id: e.target.value}})}>
                        {id === "new" ? 
                            referidos.map(m => (
                                <MenuItem key={m.id} value={m.id}>
                                    {m.name}
                                </MenuItem>
                            ))
                        : <></>}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Email 1" variant="outlined" defaultValue={cliente.email1} onChange={(e) => setCliente({...cliente, email1: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Email 2" variant="outlined" defaultValue={cliente.email2} onChange={(e) => setCliente({...cliente, email2: e.target.value})} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Domicilio 1" variant="outlined" defaultValue={cliente.direccion1} onChange={(e) => setCliente({...cliente, direccion1: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Domicilio 2" variant="outlined" defaultValue={cliente.direccion2} onChange={(e) => setCliente({...cliente, direccion2: e.target.value})} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Teléfono" variant="outlined" defaultValue={cliente.telefono1} onChange={(e) => setCliente({...cliente, telefono1: e.target.value})} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <TextField fullWidth id="outlined-basic" label="Archivo" variant="outlined" defaultValue={cliente.telefono2} onChange={(e) => setCliente({...cliente, telefono2: e.target.value})}/>
                    </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                    <Grid item md={id !== "new" ? 6 : 12} xs={12} sx={{padding: 1}}>
                        <DesktopDatePicker label="Alta actividad" inputFormat='DD/MM/YYYY' value={fechaInicio} onChange={handleInicioDate} renderInput={(params) => <TextField fullWidth {...params} />} />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: 1}}>
                        <DesktopDatePicker label="Baja actividad" inputFormat='DD/MM/YYYY' value={fechaFin} onChange={handleFinDate} renderInput={(params) => <TextField fullWidth {...params} />} />
                    </Grid>
                </Grid>
                </LocalizationProvider>
            </Paper>
            <Paper fullWidth sx={{padding: 1}}>
                <TextField fullWidth id="outlined-basic" label="Notas" variant="outlined" multiline rows={4} defaultValue={cliente.notas} onChange={(e) => setCliente({...cliente, notas: e.target.value})} />
            </Paper>
            <Grid container sx={{marginY: 1}}>
                <Grid item md={6} xs={12}>
                    <Paper fullWidth sx={{paddingRight: 1}}>
                        <Typography sx={{paddingLeft: 2, paddingTop: 1}} variant='subtitle1'>Casos</Typography>
                        <List>
                            {casos.map((m, i) => (
                                <>
                                <Divider component="li" />
                                <ListItemButton key={i} component={Link} to={"/caso/" + m.id}>
                                    <ListItemText>{m.descripcion}</ListItemText>
                                </ListItemButton>
                                </>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12} sx={{paddingLeft: 1}}>
                    <Paper fullWidth>
                        <Typography sx={{paddingLeft: 2, paddingTop: 1}} variant='subtitle1'>Impuestos</Typography>
                        <List>
                            {cliente.impuestos.map((m, i) => (
                                <>
                                <Divider component="li" />
                                <ListItem key={i}>
                                    <ListItemText>{m.id}</ListItemText>
                                </ListItem>
                                </>
                            ))}
                            <Divider component="li" />
                            <ListItemButton onClick={() => setOpenModal(true)}>
                                <ListItemText>Añadir/quitar impuesto</ListItemText>
                            </ListItemButton>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Button onClick={guardar}>Guardar</Button>
            {id != "new" ? <Button variant="contained" color="error" onClick={eliminar} sx={{marginLeft: 2}}>Eliminar</Button> : <></>}

            <Modal
            open={openModal}
            onClose={() => {setOpenModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={{position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,}}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Añadir impuesto
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Selecciona los impuestos a añadir
                    </Typography>
                    <Select
                        fullWidth
                        multiple
                        value={selectedImp}
                        onChange={handleImpChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {impuestos.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                                <Checkbox checked={selectedImp.indexOf(i.id) > -1} />
                                <ListItemText primary={i.id} />
                            </MenuItem>
                        ))}
                    </Select>
                    {/*<Button onClick={() => {guardar(); setOpenModal(false);}}>Guardar</Button>*/}
                </Box>
            </Modal>

        </Container>
    )
}