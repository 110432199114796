import * as React from 'react';
import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Avatar, Button, Tooltip} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';

import useToken from '../utils/Token';

export default function Header() {
  const { token, setToken } = useToken();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorNew, setAnchorNew] = React.useState(null);
  const [anchorInf, setAnchorInf] = React.useState(null);

  const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
      setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
      setAnchorElUser(null);
  };

  const handleOpenNew = (event) => {
    setAnchorNew(event.currentTarget);
  }

  const handleCloseNew = () => {
    setAnchorNew(null);
  }

  const handleOpenInf = (event) => {
    setAnchorInf(event.currentTarget);
  }

  const handleCloseInf = () => {
    setAnchorInf(null);
  }

  const logout = () => {
    setToken("");
    window.location.reload();
  }

  React.useEffect(() => {
    if(token){
        fetch("https://luisa.zopcloud.net:8081/myinfo", {
            method: "GET",  
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(r => {
            if(r.status != 200){
                setToken("");
                window.location.reload();
            }else{
                r.json().then(json => {
                })
            }
        })
    }
}, []);

  return (
  <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          LM
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          { token ?
          <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem onClick={handleOpenNew}>
              <Typography textAlign="center">Nuevo</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/clientes">
              <Typography textAlign="center">Clientes</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/casos">
              <Typography textAlign="center">Casos</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/referidos">
              <Typography textAlign="center">Referidos</Typography>
            </MenuItem>
            <MenuItem onClick={handleOpenInf}>
              <Typography textAlign="center">Listados</Typography>
            </MenuItem>
          </Menu>
          </>
          : <></>}
        </Box>
        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          component="a"
          href=""
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          LM
        </Typography>
        {token ?
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
              sx={{ my: 2, color: 'white', display: 'block' }}
              onClick={handleOpenNew}
            >
              Nuevo
          </Button>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorNew}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorNew)}
            onClose={handleCloseNew}
          >
            <MenuItem component={Link} to="/caso/new">
              <Typography textAlign="center">Caso</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/cliente/new">
              <Typography textAlign="center">Cliente</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/referido/new">
              <Typography textAlign="center">Colaborador</Typography>
            </MenuItem>
            <MenuItem component={Link} to="/impuesto/new">
              <Typography textAlign="center">Impuesto</Typography>
            </MenuItem>
          </Menu>
          <Button
            component={Link}
            to="/clientes"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Clientes
          </Button>
          <Button
            component={Link}
            to="/casos"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Casos
          </Button>
          <Button
            component={Link}
            to="/referidos"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Colaboradores
          </Button>
          <Button
            component={Link}
            to="/lista"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Listados
          </Button>
        </Box>
        : <></>}

        {token ?
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Luisa" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={logout}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
        : <></>}
      </Toolbar>
    </Container>
  </AppBar>
  );
}