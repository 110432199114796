import { Container, Typography, Box } from '@mui/material';
import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import useToken from '../utils/Token';
import { Navigate } from 'react-router-dom';

const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'nif', headerName: 'NIF', flex: 1 },
    { field: 'nombre', headerName: 'Nombre', flex: 1},
    { field: 'referido', headerName: 'Ref.', flex: 1, valueGetter: (p) => p.value ? p.value.name : ""},
];

export default function Clientes() {
    const { token } = useToken();
    const [r, setR] = React.useState([]);

    React.useEffect(() => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/clientes", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setR(json);
            }))
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/login" replace={true} />
        )
    }

    return(
        <Container>
            <Typography variant='h2'>Clientes</Typography>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={r}
              columns={columns}
              pageSize={5}
              components={{ Toolbar: QuickSearchToolbar }}
              onCellDoubleClick={(p) => window.location.replace("/cliente/" + p.id)}
            />
            </div>
        </Container>
    )
}

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
}