import { Container, Typography } from '@mui/material';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useToken from '../utils/Token';
import { Navigate } from 'react-router-dom';

const columns = [
    { field: 'id', headerName: 'Impuesto', flex: 1 },
    { field: 'tipo', headerName: 'Tipo', flex: 1},
];

export default function Impuestos() {
    const { token } = useToken();
    const [r, setR] = React.useState([]);

    React.useEffect(() => {
        if(token){
            fetch("https://luisa.zopcloud.net:8081/impuestos", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(r => r.json().then(json => {
                setR(json);
            }))
        }
    }, []);

    if(!token){
        return(
            <Navigate to="/login" replace={true} />
        )
    }

    return(
        <Container>
            <Typography variant='h2'>Impuestos</Typography>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={r}
              columns={columns}
              pageSize={5}
              //onCellDoubleClick={(p) => window.location.replace("/impuesto/" + p.id)}
            />
            </div>
        </Container>
    )
}